var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"serviceForm"},[_c('EKDialog',{ref:"serviceModal",staticClass:"ekService",attrs:{"title":"إضافة خدمة جديدة","btnText":"خدمة جديدة","placeholder":"ابحث عن خدمة"},on:{"ok":_vm.submitAddService,"open":_vm.openDialog,"search":_vm.search},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('EKInputText',{attrs:{"rules":[
          {
            type: 'required',
            message: 'اسم الخدمة مطلوب',
          } ],"label":"اسم الخدمة","placeholder":"ادخل اسم الخدمة","name":"name"},model:{value:(_vm.serviceDto.name),callback:function ($$v) {_vm.$set(_vm.serviceDto, "name", $$v)},expression:"serviceDto.name"}}),_c('EKInputTextarea',{attrs:{"label":"شرح الخدمة","rules":[
          {
            type: 'required',
            message: 'يرجى ملىء شرح الخدمة',
          } ],"placeholder":"ادخل شرح الخدمة","name":"description","rows":"5"},model:{value:(_vm.serviceDto.description),callback:function ($$v) {_vm.$set(_vm.serviceDto, "description", $$v)},expression:"serviceDto.description"}}),_c('EKInputImage',{ref:"servImg",attrs:{"label":"صورة الخدمة","required":"","title":"أرفق الملف هنا أو اضغط لتحميل","value":_vm.serviceDto.documents[0]
            ? _vm.serviceDto.documents[0].path
              ? _vm.serviceDto.documents[0].path.replace(
                  /^/,
                  _vm.$store.getters['app/domainHost']
                )
              : ''
            : ''},on:{"input":function($event){return _vm.addNewImage($event)}}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }