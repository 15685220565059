<template>
  <ValidationObserver ref="serviceForm">
    <EKDialog
      class="ekService"
      ref="serviceModal"
      title="إضافة خدمة جديدة"
      btnText="خدمة جديدة"
      placeholder="ابحث عن خدمة"
      @ok="submitAddService"
      @open="openDialog"
      @search="search"
    >
      <template #body>
        <EKInputText
          v-model="serviceDto.name"
          :rules="[
            {
              type: 'required',
              message: 'اسم الخدمة مطلوب',
            },
          ]"
          label="اسم الخدمة"
          placeholder="ادخل اسم الخدمة"
          name="name"
        />
        <EKInputTextarea
          label="شرح الخدمة"
          v-model="serviceDto.description"
          :rules="[
            {
              type: 'required',
              message: 'يرجى ملىء شرح الخدمة',
            },
          ]"
          placeholder="ادخل شرح الخدمة"
          name="description"
          rows="5"
        />
        <EKInputImage
        ref="servImg"
          label="صورة الخدمة"
          required
          title="أرفق الملف هنا أو اضغط لتحميل"
          :value="
            serviceDto.documents[0]
              ? serviceDto.documents[0].path
                ? serviceDto.documents[0].path.replace(
                    /^/,
                    $store.getters['app/domainHost']
                  )
                : ''
              : ''
          "
          @input="addNewImage($event)"
        ></EKInputImage>
      </template>
    </EKDialog>
  </ValidationObserver>
</template>
<style></style>
<script>
import EKDialog from "@Ekcore/components/EK-dialog";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import EKInputTextarea from "@Ekcore/components/EK-forms/EK-input-textarea";
import EKInputImage from "@Ekcore/components/EK-forms/EK-input-image";
import { mapState, mapActions } from "vuex";
import { ValidationObserver } from "vee-validate";
import { nullGuid } from "@/EK-core/util/global";
export default {
  components: {
    EKDialog,
    EKInputText,
    EKInputTextarea,
    EKInputImage,
    ValidationObserver,
  },
  computed: {
    ...mapState({
      services: (state) => state.service.services,
      serviceDto: (state) => state.service.serviceDto,
    }),
  },
  methods: {
    ...mapActions(["addService"]),
    openDialog() {
      this.$store.commit("RESET_SERVICE_FORM");
    },
    submitAddService() {
      this.$refs.serviceForm.validate().then((success) => {
        if (success && this.serviceDto.documents[0].file) {
          this.addService(this.serviceDto).then(() => {
            this.$store.commit("RESET_SERVICE_FORM");
            this.$refs.serviceModal.close();
            this.$refs.serviceForm.reset();
            this.$refs.servImg.reset();
          });
        }
      });
    },
    search(query) {
        this.$store.commit('Set_Search_Dto', {
            keys: ['name'],
            query
        })
    },
    addNewImage(img) {
      this.serviceDto.documents[0] = {
        id: nullGuid,
        file: img,
        path: "",
      };
    },
  },
};
</script>
